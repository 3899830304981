<template>
  <div v-if="getUserDetails" class="dashboard">
    <h1>{{ $t('orders.current_orders') }}</h1>

    <div class="orders-header">
      <div v-if="getOrdersStats" class="orders-view">
        <div class="buy-stat">
          <div class="buy-stat-title">
            <img src="@/assets/svg/v2/portfolio/cote.svg" alt="icône d'achat" />
            <h3>{{ $t('product_page.orders.buy_orders') }}</h3>
          </div>
          <div class="buy-stat-content">
            <p>
              {{ $t('orders.total_orders') }} <span>{{ getOrdersStats.buy_orders || 0 }}</span>
            </p>
            <p>
              {{ $t('orders.total_amount') }} <span>{{ formatNumberWithSpaces(getOrdersStats.buy_amount / 100) + ' EUR' || 0 }}</span>
            </p>
          </div>
        </div>
        <hr class="stat-separator" />
        <div class="sell-stat">
          <div class="sell-stat-title">
            <img
              src="@/assets/svg/v2/portfolio/crossRed.svg"
              alt="icône de vente"
            />
            <h3>{{ $t('product_page.orders.sell_orders') }}</h3>
          </div>
          <div class="sell-stat-content">
            <p>
              {{ $t('orders.total_orders') }} <span>{{ getOrdersStats.sell_orders || 0 }}</span>
            </p>
            <p>
              {{ $t('orders.total_amount') }} <span>{{ formatNumberWithSpaces(getOrdersStats.sell_amount / 100) + ' EUR' || 0 }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="searchbar-filter">
        <div class="orders-searchbar">
          <input v-model="search" type="text" :placeholder="$t('orders.search')" />
          <button>
            <img src="@/assets/svg/v2/search.svg" alt="Search icon" />
          </button>
        </div>

        <div class="orders-filters">
          <h4 class="hidden-tel">{{ $t('commons.filter') }}</h4>

          <div>
            <el-select
              v-model="filterStatus"
              multiple
              :placeholder="$t('commons.select')"
              style="min-width: 160px"
              clearable
            >
              <el-option
                v-for="item in filtersStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div class="transactions">
      <el-table
        :data="visibleTransactionsFiltered"
        class="bottles-list orders-list"
        stripe
      >
        <el-table-column :label="$t('commons.status')" prop="status" width="130">
          <template v-slot="{ row }">
            <p class="title">{{ getStatus(row) }}</p>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('orders.direction')"
          prop="transaction"
          width="140"
        >
          <template v-slot="{ row }">
            <button v-if="row.type === 0" class="live-order-list-buy">
              <img
                alt="Buy order"
                height="16"
                src="@/assets/svg/v2/products/buyOrder.svg"
                width="16"
              />
              <p>{{ $t('search.buy') }}</p>
            </button>

            <button v-if="row.type === 1" class="live-order-list-sell">
              <img
                alt="Sell order"
                height="16"
                src="@/assets/svg/v2/products/sellOrder.svg"
                width="16"
              />
              <p>{{ $t('search.sell') }}</p>
            </button>

            <button v-if="row.type === 2" class="live-order-list-giver">
              <img
                alt="Sell order"
                height="16"
                src="@/assets/svg/v2/products/swap.svg"
                width="16"
              />
              <p>Giver</p>
            </button>

            <button v-if="row.type === 3" class="live-order-list-taker">
              <img
                alt="Sell order"
                height="16"
                src="@/assets/svg/v2/products/swap.svg"
                width="16"
              />
              <p>Taker</p>
            </button>

            <button v-if="row.type === 4" class="live-order-list-sell">
              <img
                alt="Swap taker order"
                height="16"
                src="@/assets/svg/v2/products/blocktrade.svg"
                style="background: #2667ff !important"
                width="16"
              />
              <p>BlockTrade</p>
            </button>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('orders.creation_date')"
          prop="date"
          sortable
          width="180"
        >
          <template v-slot="{ row }">
            <a class="title">{{ row.created_at }}</a>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('commons.date_expire')"
          sortable
          width="180"
        >
          <template v-slot="{ row }">
            <p class="title" v-if="row.enabled === 1 && row.sold === 0">
              {{ row.countdown || '-' }}
            </p>
            <p v-else>-</p>
          </template>
        </el-table-column>

        <el-table-column
          label="Ticker"
          prop="ticker_maitre"
          width="130"
        >
          <template v-slot="{ row }">
            <button v-if="row.bottle.ticker" class="ticker-maitre">
              {{ row.bottle.ticker }}
            </button>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('search.name')"
          min-width="190"
          prop="title"
        >
          <template v-slot="{ row }">
            <a :href="'/product/' + row.isbn" class="title">{{ row.bottle.title }}</a>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('orders.price')"
          width="70"
          prop="price"
          sortable
        >
          <template v-slot="{ row }">
            <button class="title">
              {{ row.price / 100 }}
            </button>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('orders.quantity')"
          width="110"
          prop="quantity"
          sortable
        >
          <template v-slot="{ row }">
            <button class="title">{{ row.quantity }}</button>
          </template>
        </el-table-column>

        <el-table-column label="" prop="title" width="130">
          <template v-slot="{ row }">
            
            <div v-if="row.enabled === 1 && row.sold === 0 && !row.is_pretrade && !row.dispute.dispute_created_at" class="buttons">
              <button v-if="row.order_type === 4" class="download-invoice" @click="copyLink(row)">
                <el-tooltip
                  :content="$t('product_page.orders.blocktrade_link_copy')"
                  class="tooltip"
                  effect="dark"
                  placement="top"
                >
                  <img alt="Blocktrade link" src="@/assets/svg/v2/link.svg" />
                </el-tooltip>
              </button>

              <button class="download-invoice" @click="editOrder(row)">
                <el-tooltip
                  :content="$t('product_page.orders.update_order')"
                  class="tooltip"
                  effect="dark"
                  placement="top"
                >
                  <img alt="Edit order" src="@/assets/svg/v2/edit.svg" />
                </el-tooltip>
              </button>

              <button class="download-invoice" @click="deleteOrderUI(row.uuid)">
                <el-tooltip
                  :content="$t('product_page.orders.delete_order')"
                  class="tooltip"
                  effect="dark"
                  placement="top"
                >
                  <img alt="Delete order" src="@/assets/svg/v2/delete.svg" />
                </el-tooltip>
              </button>
            </div>

            <div v-else-if="row.sold === 1 && row.shipment.tracking_number" class="buttons">
              <button class="download-invoice" @click="showDetailShippingModal(row)">
                <el-tooltip
                  :content="$t('product_page.orders.shipping_details_see')"
                  class="tooltip"
                  effect="dark"
                  placement="top"
                >
                  <img src="@/assets/svg/v2/products/truck.svg" alt="box" />
                </el-tooltip>
              </button>

              <button class="download-invoice" @click="downloadJpg(row)" v-if="row.order_type !== 0 && row.shipment.picture">
                <el-tooltip
                  :content="$t('product_page.orders.shipping_download_sheet')"
                  class="tooltip"
                  effect="dark"
                  placement="top"
                >
                  <img
                    src="@/assets/svg/v2/portfolio/import.svg"
                    alt="Shipment download"
                  />
                </el-tooltip>
              </button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>

  <el-drawer v-model="drawerUpdateOrder" title="" :size="drawerSize" @close="closeDrawerUpdateOrder">
    <createOrder @close="closeDrawerUpdateOrder" :orderType="returnOrderType" :isUpdate="true" :isUpdateData="orderEdit" />
  </el-drawer>

  <CommonModal
    :modal-title="$t('product_page.orders.shipping_details')"
    v-if="isShippingDetailsModalOpen"
    size="x-small"
    @close="closeShippingDetails"
  >
    <shippingDetails :order="rowToSeeDetails" />
  </CommonModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ElMessage } from 'element-plus'
import { filter } from 'lodash'
import createOrder from '@/components/modules/dialogs/createOrder.vue'
import shippingDetails from '@/components/modules/dialogs/shippingDetails.vue'
import CommonModal from '@/components/commons/modal.vue'
import moment from 'moment'

export default {
  components: {
    CommonModal,
    shippingDetails,
    createOrder
  },
  data() {
    return {
      drawerUpdateOrder: false,
      isModalSellVisible: false,
      orderEdit: {},
      isShippingDetailsModalOpen: false,
      rowToSeeDetails: null,
      availableStatus: [],
      filterStatus: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      search: '',
      filtersStatusList: [
        {
          label: this.$t('product_page.orders.active'),
          value: 1
        },
        {
          label: this.$t('product_page.orders.deleted'),
          value: 2
        },
        {
          label: this.$t('product_page.orders.match_pending'),
          value: 3
        },
        {
          label: this.$t('product_page.orders.match'),
          value: 4
        },
        {
          label: this.$t('product_page.orders.ship_send_pending_seller'),
          value: 5
        },
        {
          label: this.$t('product_page.orders.ship_send_pending_buyer'),
          value: 6
        },
        {
          label: this.$t('product_page.orders.delivery_pending'),
          value: 7
        },
        {
          label: this.$t('product_page.orders.pickup_available'),
          value: 8
        },
        {
          label: this.$t('product_page.orders.delivery_end'),
          value: 9
        },
        {
          label: this.$t('product_page.orders.dispute'),
          value: 10
        },
        {
          label: this.$t('product_page.orders.bo_pending'),
          value: 11
        },
        {
          label: this.$t('product_page.orders.end_transaction'),
          value: 12
        },
      ]
    }
  },
  async mounted() {
    await this.getOrderHistory()

    if (!this.getUserDetails) {
      this.getClient()
    }
  },
  watch: {
    filterStatus() {
      if (this.filterStatus.length === 0) {
        this.filterStatus = [1, 3, 4, 5, 6, 7, 8, 9, 10, 11];
      }
    },
    getCreateOrderDialog() {
      if (this.getCreateOrderDialog === 'updateBlockTrade' || this.getCreateOrderDialog === 'updateBuyOrder' || this.getCreateOrderDialog === 'updateSellOrder') {
        this.drawerUpdateOrder = true;
      } else {
        this.drawerUpdateOrder = false;
      }
    }
  },
  computed: {
    ...mapGetters({
      transactions: 'getTransactions',
      getCreateOrderDialog: 'getCreateOrderDialog',
      getUserDetails: 'getUserDetails',
      getOrdersStats: 'getOrdersStats'
    }),
    enabledOrders() {
      return filter(this.getMyOrders, { enabled: 1 })
    },
    soldOrders() {
      return filter(this.getMyOrders, { enabled: 0, sold: 1 })
    },
    cancelOrders() {
      return filter(this.getMyOrders, { enabled: 0, sold: 0 })
    },
    visibleTransactionsFiltered() {
      return filter(this.transactions, (o) => {           

        return ((this.filterStatus.includes(o.status_code) && o.bottle.ticker.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)) ||
          (this.filterStatus.includes(o.status_code) && o.bottle.title.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
          (this.filterStatus.includes(o.status_code) && String(o.created_at).indexOf(this.search) != -1) ||
          (this.filterStatus.includes(o.status_code) && String(o.countdown || '').indexOf(this.search) != -1) ||
          (this.filterStatus.includes(o.status_code) && String(o.price).indexOf(this.search) != -1)
        
      });
    },
    returnStatusFilter() {
      const status = [];

        this.availableStatus.forEach(a => {
          if (!status.includes(a)) {
            status.push(a);
          }
        })

      return status;
    },
    drawerSize() {
      if (window.innerWidth < 500) {
        return '100%';
      }
      return '40%';
    },
    returnOrderType() {
      if (this.getCreateOrderDialog === 'updateBlockTrade') {
        return 'blocktrade';
      } else if (this.getCreateOrderDialog === 'updateSellOrder') {
        return 'sell';
      }
      return 'buy';
    }
  },
  methods: {
    ...mapActions({
      product: 'product',
      getOrderHistory: 'getOrderHistory',
      deleteOrder: 'deleteOrder',
      updateOrder: 'updateOrder',
      getClient: 'getAccountDetails',
      getOrder: 'getOrder'
    }),
    async deleteOrderUI(uuid) {
      await this.deleteOrder({ order_uuid: uuid });
      await this.getOrderHistory();
    },
    async closeModalRefresh() {
      this.closeModal();
      await this.getOrderHistory();
    },
    async editOrder(order) {
      if (order.order_type === 0) {
        const payload = { isbn: order.isbn }
        await this.product(payload)
        this.orderEdit = order
        this.$store.commit('CREATE_ORDER_DIALOG', 'updateBuyOrder')
      }
      if (order.order_type === 1) {
        const payload = { isbn: order.isbn }
        await this.product(payload)
        this.orderEdit = order
        this.$store.commit('CREATE_ORDER_DIALOG', 'updateSellOrder')
      }
      if (order.order_type === 2 || order.order_type === 3) {
        const payload = { isbn: order.isbn }
        await this.product(payload)
        this.orderEdit = order
        this.$store.commit('CREATE_ORDER_DIALOG', 'updateSwapOrder')
      }
      if (order.order_type === 4) {
        // const payload = { isbn: order.isbn };
        // await this.product(payload);
        this.orderEdit = order;
        this.$store.commit('CREATE_ORDER_DIALOG', 'updateBlockTrade');

        // const payload = {
        //   uuid: order.uuid,
        //   shipment: 0
        // }

        // this.getOrder(payload);
      }
    },
    dateConvert(date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss')
    },
    getStatus(row) {
      if (row.enabled === 1 && row.sold === 0 && row.valid && row.is_pretrade === 0) {

        this.availableStatus.push(this.$t('product_page.orders.active'));
        return this.$t('product_page.orders.active');

      } else if (row.enabled === 0 && row.sold === 0 && !row.shipment.tracking_number) {

        this.availableStatus.push(this.$t('product_page.orders.deleted'));
        return this.$t('product_page.orders.deleted');

      } else if (row.enabled === 1 && row.sold === 0 && row.is_pretrade === 1 && row.order_type !== 0) {

        this.availableStatus.push(this.$t('product_page.orders.match_pending'));
        return this.$t('product_page.orders.match_pending');

      } else if (row.enabled === 1 && row.sold === 0 && row.is_pretrade === 1 && row.order_type === 0) {

        this.availableStatus.push(this.$t('product_page.orders.match'));
        return this.$t('product_page.orders.match');

      } else if (row.sold === 1 && row.shipment.package_status === 'Info Received') {

        this.availableStatus.push(this.$t('product_page.orders.ship_send_pending_seller'));
        return this.$t('product_page.orders.ship_send_pending_seller');

      } else if (row.sold === 1 && row.shipment.package_status && row.order_type === 0) {

        this.availableStatus.push(this.$t('product_page.orders.ship_send_pending_buyer'));
        return this.$t('product_page.orders.ship_send_pending_buyer');

      } else if (row.sold === 1 && row.shipment.package_status === 'In Transit') {

        this.availableStatus.push(this.$t('product_page.orders.delivery_pending'));
        return this.$t('product_page.orders.delivery_pending');

      } else if (row.sold === 1 && row.shipment.package_status === 'Available for pickup') {

        this.availableStatus.push(this.$t('product_page.orders.pickup_available'));
        return this.$t('product_page.orders.pickup_available');
        
      } else if (row.sold === 1 && row.shipment.package_status === 'Delivered') {

        this.availableStatus.push(this.$t('product_page.orders.delivery_end'));
        return this.$t('product_page.orders.delivery_end');

      } else if (row.sold === 1 && row.dispute.dispute_created_at && !row.dispute.dispute_resolve_at) {

        this.availableStatus.push(this.$t('product_page.orders.dispute'));
        return this.$t('product_page.orders.dispute');

      } else if (!row.valid && row.enabled === 1) {

        this.availableStatus.push(this.$t('product_page.orders.bo_pending'));
        return this.$t('product_page.orders.bo_pending');

      } else if (row.shipment.tracking_number) {

        this.availableStatus.push(this.$t('product_page.orders.end_transaction'));
        return this.$t('product_page.orders.end_transaction');

      }
    },
    showDetailShippingModal(row) {
      this.rowToSeeDetails = row
      this.isShippingDetailsModalOpen = true
    },
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
      this.$store.commit('CREATE_ORDER_DIALOG', null)
    },
    closeShippingDetails() {
      this.isShippingDetailsModalOpen = false
    },
    downloadJpg(row) {
      const splitPictures = row.shipment.picture.split(',');

      splitPictures.forEach(o => {
          const base64String = o.replaceAll('"', '')
          const blob = this.base64ToBlob(base64String, 'image/jpg')
    
          const fileName = `TEP_UPS_${Date.now()}.jpg`
          this.saveBlob(blob, fileName)
      });

    },
    base64ToBlob(base64String) {
      const byteCharacters = atob(base64String)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)

      return new Blob([byteArray], { type: 'image/jpg' })
    },
    saveBlob(blob, fileName) {
      const url = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.download = fileName
      link.click()

      setTimeout(() => {
        URL.revokeObjectURL(url)
      }, 10000)
    },
    formatNumberWithSpaces(number) {
      if (!number) return 0;
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    copyLink(row) {
      navigator.clipboard.writeText(`https://thexchangeplatform.io/product/order/informations?token=${row.blocktrade_token}`);

      ElMessage({
        message: this.$t('product_page.orders.success_copy'),
        type: 'success',
        grouping: true,
        duration: 5000,
      })
    },
    closeDrawerUpdateOrder() {
      this.$store.commit('CREATE_ORDER_DIALOG', null);
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

.dashboard {
  margin: 30px;
  margin-top: 20px;
}

.orders-header {
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 25px;
  margin: 20px 0;
}

.orders-view {
  display: flex;
  flex-direction: row;
  max-width: 600px;
  background: #333;
  border-radius: 12px;
  border: solid 1px rgba(255, 255, 255, 0.24);
}

.buy-stat,
.sell-stat {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}
.buy-stat {
  background: linear-gradient(
    to bottom,
    rgba(132, 204, 22, 0.16),
    rgba(132, 204, 22, 0)
  );
}

.sell-stat {
  background: linear-gradient(
    to bottom,
    rgba(244, 63, 94, 0.16),
    rgba(244, 63, 94, 0)
  );
}

.stat-separator {
  border-right: none;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.buy-stat-title,
.sell-stat-title {
  margin: 20px;
  margin-top: 10px;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.buy-stat-content,
.sell-stat-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;

  p {
    margin: 0 20px;
  }
}

.buy-stat-content p,
.sell-stat-content p {
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
}

.buy-stat-content p span,
.sell-stat-content p span {
  font-size: 18px;
  color: white;
  right: 0px;
}

.searchbar-filter {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.orders-searchbar {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  
  input {
    box-sizing: border-box;
    width: 240px;
    height: 32px;
    border: none;
    background: #333;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 5px 10px;
    font-size: 15px;
  }
  button {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 32px;
    background: #2667ff;
    border: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 5px 12px;
    font-size: 15px;
  }
}

.orders-filters {
  display: flex;
  flex-flow: row;
  justify-content: left;
  align-items: last baseline;

  div > .el-select > .el-select__wrapper > .el-select__selection  {
    width: 100%;
  }

  h4 {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 500;
    min-width: 70px;
  }
}

.dashboard {
  min-height: 52%;
  margin-top: 20px;

  h1 {
    margin-bottom: 6px;
  }

  .display-deleted-orders {
    padding-left: 30px;
  }
}

.transactions {
  border-radius: 12px;
  border: 1px solid $border;
  background: #333;
  flex-direction: column;
  align-items: flex-start;
  margin: 15px 0;
  overflow: hidden;
}

.disabled-row {
  background-color: #f5f5f5;
  color: #999;
}

.buttons {
  display: flex;
  justify-content: left;
  gap: 20px;
}

.title,
.price {
  display: flex;
  text-align: start;
  word-break: keep-all;
  color: white;
  font-size: 15px;
  font-weight: 500;
  border: 0;
  background: transparent;
}

.ticker-maitre,
.status {
  border-radius: 6px;
  border: 1px solid $border;
  background: transparent;
  padding: 3px;
  color: white;
}

.status-paid {
  color: #add7f6;
  border: 1px solid #add7f6;
}

.delivery {
  border: none;
  color: $grey40;
  background: transparent;
}

.cell {
  display: flex;
  justify-content: start;
}

.live-orders {
  justify-content: flex-start;

  .case-buy,
  .case-sell,
  .case-taker,
  .case-giver {
    width: 90px;

    .empty-order {
      margin-left: 10px;
    }
  }

  .case-sell {
    .empty-order {
      margin-left: 22px;
    }
  }

  .case-taker {
    width: 105px;

    .empty-order {
      margin-left: 33px;
    }
  }

  .case-giver {
    width: 105px;
    margin-left: 10px;

    .empty-order {
      margin-left: 33px;
    }
  }
}

.live-order-list-buy,
.live-order-list-sell,
.live-order-list-taker,
.live-order-list-giver {
  display: flex;
  border-radius: 50px;
  border: 1px solid $border;
  background: #444;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
  height: 27px;
  width: max-content;
  padding: 2px 10px;
}

.live-order-list-buy,
.live-order-list-sell,
.live-order-list-taker,
.live-order-list-giver {
  justify-content: center;
  align-items: center;
  margin-left: 0;

  img {
    background: #84cc16;
    border-radius: 50%;
  }

  p {
    color: white;
    font-size: 15px;
    font-weight: 500;
    margin-left: 6px;
  }

  span {
    color: $grey40;
    font-size: 15px;
    font-weight: 500;
    margin-left: 2px;
  }
}

.live-order-list-sell {
  margin-left: 0;

  img {
    background: #f43f5e;
  }
}

.live-order-list-taker,
.live-order-list-giver {
  img {
    background: #a855f7;
  }
}

.live-order-list-giver {
  img {
    background: $blue;
  }
}

.download-invoice {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;

  img {
    width: 20px;
  }
}

.shipment-send {
  background: green;
  border-radius: 50%;
  padding: 4px;
}

.el-table tr,
.el-table th.el-table__cell {
  background-color: #333333;
  font-size: 15px;
  font-weight: 500;
}

.el-table th.el-table__cell.is-leaf,
.el-table td.el-table__cell {
  border-bottom: 1px solid $border;
}

.el-table thead {
    color: white;
  }

.el-table__body tr:hover > td.el-table__cell {
  background-color: transparent;
}

.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell,
.el-table--striped:hover
  .el-table__body
  tr.el-table__row--striped:hover
  td.el-table__cell {
  background: #ffffff32;
}

.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: #444444;
}

.el-table__inner-wrapper::before {
  background-color: transparent;
}

@media screen and (max-width: 500px) {

  .hidden-tel {
    display: none;
  }

  .orders-list {
    display: block !important;
  }

  .dashboard {
    max-width: 100%;
    box-sizing: border-box;
    margin: 5px;
    margin-top: 20px;

    h1 {
      margin-bottom: 10px !important;
      padding-left: 10px;
    }
  }

  .orders-header {
    overflow: hidden;
    margin: 8px;
    gap: 15px;
    box-sizing: border-box;
  }

  .orders-view {
    margin-top: 5px;
    flex-direction: column;
    border-radius: 12px;
    overflow: hidden;
  }

  .buy-stat-title,
  .sell-stat-title {
    margin-left: 5px;
  }

  .buy-stat-content,
  .sell-stat-content {
    p {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .stat-separator {
    width: 100%;
    border-top: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .searchbar-filter {
    flex-direction: column-reverse;
    width: 100%;
    gap: 10px;
    margin-bottom: 10px;
  }

  .orders-searchbar {
    margin-left: 0;

    input {
      width: 80%;
    }

    button {
      width: 20%;
      justify-content: center;
    }
  }

  .transactions {
    margin: 5px;
  }

  .display-deleted-orders {
    padding-left: 7px !important;
  }
}
</style>
